define('keygen/controllers/licenses/show', ['exports', 'keygen/controllers/application', 'ember', 'npm:copy-to-clipboard'], function (exports, _keygenControllersApplication, _ember, _npmCopyToClipboard) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenControllersApplication['default'].extend({
    isCheckoutModalVisible: false,
    hasLicenseFileIncludedRelationships: false,
    isLicenseFileEncrypted: false,
    hasLicenseFileTTL: false,
    hasNewlyGeneratedLicenseFile: false,

    licenseFileEncryptionAlgorithm: 'aes-256-gcm',
    licenseFileIncludedRelationships: [],
    licenseFileTTL: 2592000,

    licenseFileSigningAlgorithm: computed('model.scheme', function () {
      var scheme = this.get('model.scheme');
      switch (scheme) {
        case 'ED25519_SIGN':
          return 'ed25519';
        case 'RSA_2048_PKCS1_PSS_SIGN_V2':
        case 'RSA_2048_PKCS1_PSS_SIGN':
          return 'rsa-pss-sha256';
        case 'RSA_2048_PKCS1_SIGN_V2':
        case 'RSA_2048_PKCS1_ENCRYPT':
        case 'RSA_2048_JWT_RS256':
        case 'RSA_2048_PKCS1_SIGN':
          return 'rsa-sha256';
        default:
          return 'ed25519';
      }
    }),

    licenseFileIncludedRelationshipSelectedOptions: computed('licenseFileIncludedRelationships', function () {
      var options = this.get('licenseFileIncludedRelationshipOptions');
      var selected = this.get('licenseFileIncludedRelationships');

      return options.filter(function (o) {
        return selected.includes(o.value);
      });
    }),

    licenseFileIncludedRelationshipOptions: [{ name: 'Entitlements', value: 'entitlements' }, { name: 'Product', value: 'product' }, { name: 'Policy', value: 'policy' }, { name: 'Group', value: 'group' }, { name: 'Owner', value: 'owner' }, { name: 'Users', value: 'users' }],

    licenseFileTTLSelectedOption: computed('licenseFileTTL', function () {
      var options = this.get('licenseFileTTLOptions');
      var selected = this.get('licenseFileTTL');

      return options.find(function (o) {
        return o.value === selected;
      });
    }),

    licenseFileTTLOptions: [{ name: 'No TTL', value: null }, { name: '1 Day', value: 86400 }, { name: '2 Days', value: 172800 }, { name: '3 Days', value: 259200 }, { name: '4 Days', value: 345600 }, { name: '5 Days', value: 432000 }, { name: '1 Week', value: 604800 }, { name: '2 Weeks', value: 1209600 }, { name: '3 Weeks', value: 1814400 }, { name: '4 Weeks', value: 2419200 }, { name: '1 Month', value: 2592000 }, { name: '31 Days', value: 2678400 }, { name: '32 Days', value: 2764800 }, { name: '5 Weeks', value: 3024000 }, { name: '2 Months', value: 5184000 }, { name: '3 Months', value: 7776000 }, { name: '4 Months', value: 10368000 }, { name: '5 Months', value: 12960000 }, { name: '6 Months', value: 15552000 }, { name: '9 Months', value: 23328000 }, { name: '1 Year', value: 31536000 }, { name: '13 Months', value: 34186698 }, { name: '2 Years', value: 63113904 }, { name: '3 Years', value: 94670856 }],

    actions: {
      openCheckoutModal: function openCheckoutModal() {
        this.set('isCheckoutModalVisible', true);
      },

      closeCheckoutModal: function closeCheckoutModal() {
        this.set('isCheckoutModalVisible', false);
        this.setProperties({
          isCheckoutModalVisible: false,
          hasLicenseFileIncludedRelationships: false,
          isLicenseFileEncrypted: false,
          hasLicenseFileTTL: false,
          hasNewlyGeneratedLicenseFile: false,
          licenseFileEncryptionAlgorithm: 'aes-256-gcm',
          licenseFileIncludedRelationships: [],
          licenseFileTTL: 2592000
        });
      },

      chooseLicenseFileIncludedRelationships: function chooseLicenseFileIncludedRelationships(rels) {
        this.set('licenseFileIncludedRelationships', rels.map(function (r) {
          return r.value;
        }));
      },

      chooseLicenseFileTTL: function chooseLicenseFileTTL(_ref) {
        var value = _ref.value;

        this.set('licenseFileTTL', value);
      },

      enableLicenseFileIncludedRelationships: function enableLicenseFileIncludedRelationships() {
        this.set('hasLicenseFileIncludedRelationships', true);
      },

      enableLicenseFileTTL: function enableLicenseFileTTL() {
        this.set('hasLicenseFileTTL', true);
      },

      toggleLicenseFileEncryption: function toggleLicenseFileEncryption() {
        this.toggleProperty('isLicenseFileEncrypted');
      },

      checkoutLicense: function checkoutLicense() {
        var _this = this;

        var include = this.get('licenseFileIncludedRelationships');
        var encrypt = this.get('isLicenseFileEncrypted');
        var ttl = this.get('licenseFileTTL');
        var license = this.get('model');

        license.checkout({ meta: { encrypt: encrypt, include: include, ttl: ttl } }).then(function (_ref2) {
          var data = _ref2.data;

          _this.setProperties({
            licenseFile: data.attributes.certificate,
            hasNewlyGeneratedLicenseFile: true
          });

          license.reload();
        });
      },

      copyLicenseFileToClipboard: function copyLicenseFileToClipboard() {
        var _this2 = this;

        var lic = this.get('licenseFile');

        setTimeout(function () {
          return _this2.set('hasRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(lic));
      },

      downloadLicenseFile: function downloadLicenseFile() {
        var lic = this.get('licenseFile');
        var blob = new Blob([lic], { type: 'text/plain' });
        var a = document.createElement('a');

        a.href = URL.createObjectURL(blob);
        a.download = 'license.lic';

        a.click();
      }
    }
  });
});