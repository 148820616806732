define('keygen/controllers/machines/show', ['exports', 'keygen/controllers/application', 'ember', 'npm:copy-to-clipboard'], function (exports, _keygenControllersApplication, _ember, _npmCopyToClipboard) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenControllersApplication['default'].extend({
    isCheckoutModalVisible: false,
    hasMachineFileIncludedRelationships: false,
    isMachineFileEncrypted: false,
    hasMachineFileTTL: false,
    hasNewlyGeneratedMachineFile: false,

    machineFileEncryptionAlgorithm: 'aes-256-gcm',
    machineFileIncludedRelationships: [],
    machineFileTTL: 2592000,

    machineFileSigningAlgorithm: computed('model.license.scheme', function () {
      var scheme = this.get('model.license.scheme');
      switch (scheme) {
        case 'ED25519_SIGN':
          return 'ed25519';
        case 'RSA_2048_PKCS1_PSS_SIGN_V2':
        case 'RSA_2048_PKCS1_PSS_SIGN':
          return 'rsa-pss-sha256';
        case 'RSA_2048_PKCS1_SIGN_V2':
        case 'RSA_2048_PKCS1_ENCRYPT':
        case 'RSA_2048_JWT_RS256':
        case 'RSA_2048_PKCS1_SIGN':
          return 'rsa-sha256';
        default:
          return 'ed25519';
      }
    }),

    machineFileIncludedRelationshipSelectedOptions: computed('machineFileIncludedRelationships', function () {
      var options = this.get('machineFileIncludedRelationshipOptions');
      var selected = this.get('machineFileIncludedRelationships');

      return options.filter(function (o) {
        return selected.includes(o.value);
      });
    }),

    machineFileIncludedRelationshipOptions: [{ name: 'Entitlements', value: 'license.entitlements' }, { name: 'Components', value: 'components' }, { name: 'License', value: 'license' }, { name: 'Product', value: 'license.product' }, { name: 'Policy', value: 'license.policy' }, { name: 'Owner', value: 'license.owner' }, { name: 'Users', value: 'license.users' }, { name: 'Group', value: 'group' }],

    machineFileTTLSelectedOption: computed('machineFileTTL', function () {
      var options = this.get('machineFileTTLOptions');
      var selected = this.get('machineFileTTL');

      return options.find(function (o) {
        return o.value === selected;
      });
    }),

    machineFileTTLOptions: [{ name: 'No TTL', value: null }, { name: '1 Day', value: 86400 }, { name: '2 Days', value: 172800 }, { name: '3 Days', value: 259200 }, { name: '4 Days', value: 345600 }, { name: '5 Days', value: 432000 }, { name: '1 Week', value: 604800 }, { name: '2 Weeks', value: 1209600 }, { name: '3 Weeks', value: 1814400 }, { name: '4 Weeks', value: 2419200 }, { name: '1 Month', value: 2592000 }, { name: '31 Days', value: 2678400 }, { name: '32 Days', value: 2764800 }, { name: '5 Weeks', value: 3024000 }, { name: '2 Months', value: 5184000 }, { name: '3 Months', value: 7776000 }, { name: '4 Months', value: 10368000 }, { name: '5 Months', value: 12960000 }, { name: '6 Months', value: 15552000 }, { name: '9 Months', value: 23328000 }, { name: '1 Year', value: 31536000 }, { name: '13 Months', value: 34186698 }, { name: '2 Years', value: 63113904 }, { name: '3 Years', value: 94670856 }],

    actions: {
      openCheckoutModal: function openCheckoutModal() {
        this.set('isCheckoutModalVisible', true);
      },

      closeCheckoutModal: function closeCheckoutModal() {
        this.set('isCheckoutModalVisible', false);
        this.setProperties({
          isCheckoutModalVisible: false,
          hasMachineFileIncludedRelationships: false,
          isMachineFileEncrypted: false,
          hasMachineFileTTL: false,
          hasNewlyGeneratedMachineFile: false,
          machineFileEncryptionAlgorithm: 'aes-256-gcm',
          machineFileIncludedRelationships: [],
          machineFileTTL: 2592000
        });
      },

      chooseMachineFileIncludedRelationships: function chooseMachineFileIncludedRelationships(rels) {
        this.set('machineFileIncludedRelationships', rels.map(function (r) {
          return r.value;
        }));
      },

      chooseMachineFileTTL: function chooseMachineFileTTL(_ref) {
        var value = _ref.value;

        this.set('machineFileTTL', value);
      },

      enableMachineFileIncludedRelationships: function enableMachineFileIncludedRelationships() {
        this.set('hasMachineFileIncludedRelationships', true);
      },

      enableMachineFileTTL: function enableMachineFileTTL() {
        this.set('hasMachineFileTTL', true);
      },

      toggleMachineFileEncryption: function toggleMachineFileEncryption() {
        this.toggleProperty('isMachineFileEncrypted');
      },

      checkoutMachine: function checkoutMachine() {
        var _this = this;

        var include = this.get('machineFileIncludedRelationships');
        var encrypt = this.get('isMachineFileEncrypted');
        var ttl = this.get('machineFileTTL');
        var machine = this.get('model');

        machine.checkout({ meta: { encrypt: encrypt, include: include, ttl: ttl } }).then(function (_ref2) {
          var data = _ref2.data;

          _this.setProperties({
            machineFile: data.attributes.certificate,
            hasNewlyGeneratedMachineFile: true
          });

          machine.reload();
        });
      },

      copyMachineFileToClipboard: function copyMachineFileToClipboard() {
        var _this2 = this;

        var lic = this.get('machineFile');

        setTimeout(function () {
          return _this2.set('hasRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(lic));
      },

      downloadMachineFile: function downloadMachineFile() {
        var lic = this.get('machineFile');
        var blob = new Blob([lic], { type: 'text/plain' });
        var a = document.createElement('a');

        a.href = URL.createObjectURL(blob);
        a.download = 'machine.lic';

        a.click();
      }
    }
  });
});